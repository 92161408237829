export const ROUTE_NAMES = {
  documents: {
    paymentRequirementsClosed: 'payment-requirements.closed',

    cardIndexesFirstIndex: 'card-indexes-first.index',
    cardIndexesFirstDetails: 'card-indexes-first.details',
    closedCardIndexesFirstDetails: 'closed-card-indexes-first.details',

    cardIndexesSecondIndex: 'card-indexes-second.index',
    cardIndexesSecondDetails: 'card-indexes-second.details',
    closedCardIndexesSecondDetails: 'closed-card-indexes-second.details'
  },
  dbo: {
    auth: {
      instruction: 'instruction'
    }
  },
  onboarding: {
    main: 'onboarding',
    auth: {
      leadFormAuth: 'lead-form-auth'
    }
  }
} as const;